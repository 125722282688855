














































































import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import SvgImage from '~/components/General/SvgImage.vue';
import { ProductPackPropsInterface } from '~/components/Product/types';
import { useUiState } from '~/composables';
import { capitalizeFirstLetter } from '~/helpers/textHelpers';

export default defineComponent({
  name: 'ProductPackModal',
  components: {
    SvgImage,
    SimpleModal,
  },
  setup() {
    const { modal } = useUiState();

    return {
      modalProps: computed<ProductPackPropsInterface>(() => modal.value.props?.productPack || {}),
      name: MODAL_NAMES.productPack,
      capitalizeFirstLetter,
    };
  },
});
